<template>
	<div id="survey-app">
		<div>
			<div class="background" v-bind:style="style.background"></div>
			<div class="surveyCont" style="overflow-y: auto; position: absolute; width: 100%;">
				<div>
					<div class="container-fluid">
						<div class="row justify-content-end">
							<div class="col col-md-3 p-0 px-3 pt-3 pt-md-3 pr-md-3 pb-md-0 pl-md-0">
								<img v-if="theme.logo !== '' && theme.logo != null && logoActive.indexOf(true) > -1" v-bind:src="theme.logo" class="img-fluid" />
								<div v-else style="min-height: 7rem;"></div>
							</div>
						</div>
						<div class="survey-wrapper">
							<div class="survey mt-5">
								<div class="effect">
									<div>
										<div>
											<div class="row justify-content-center mb-1">
												<div class="col col-lg-8 align-self-center">
													<div class="px-1 px-md-3 px-lg-5">
														<h2 class="text-center text-md-left">Accusam et justo</h2>
														<h5>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</h5>
													</div>
												</div>
											</div>

											<div class="row justify-content-center">
												<div class="col col-lg-8 align-self-center question">
													<div class="p-1 p-md-3 p-lg-5">
														<div>
															<h1>Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip?</h1>
															<p>
																Ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et
																iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.
															</p>

															<div>
																<input id="answer0" name="answer" type="radio" checked />
																<label for="answer0">Duis autem vel eum</label>
															</div>
															<div>
																<input id="answer1" name="answer" type="radio" />
																<label for="answer1">iriure dolor in hendrerit</label>
															</div>
															<div>
																<input id="answer2" name="answer" type="radio" />
																<label for="answer2">vulputate velit</label>
															</div>
															<div>
																<input id="answer3" name="answer" type="radio" />
																<label for="answer3">esse molestie</label>
															</div>

															<div class="text-center mt-3">
																<button type="button" class="btn">WEITER</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'DesignerPreview',
	props: {
		theme: Object,
		backgroundPicActive: Array,
		logoActive: Array,
	},
	data() {
		return {
			style: {
				background: null,
			},
		};
	},
	mounted() {
		this.createCssClasses();
	},
	methods: {
		createCssClasses: function() {
			this.style.background = {
				position: 'absolute',
				'background-color': this.theme.background_color,
			};

			if (this.theme.background_picture && this.backgroundPicActive.indexOf(true) > -1) {
				this.style.background['--image'] = 'url(' + this.theme.background_picture + ')';
				// this.style.background['--image'] = 'url(/' + this.survey.theme.backgroundPicture + ')';
			}
			if (this.theme.background_picture_opacity) {
				this.style.background['--opacity'] = this.theme.background_picture_opacity;
			}

			let style = document.getElementById('preview-style');
			if (style === null) {
				style = document.createElement('style');
				style.setAttribute('id', 'preview-style');
			}

			style.innerHTML = `
            #survey-app .first-step, #survey-app .last-step {
                margin-top: 20vh;
                margin-bottom: 20vh;
            }

            #survey-app .surveyCont {
                height: calc(100% - 40px) !important;
            }

            @media (max-width: 500px) {
                .first-step, .last-step {
                    margin-top: 0vh;
                }
                .survey-wrapper .footer {
                    padding-top: 2px;
                }
                .surveyCont {
                    height: calc(100% - 95px) !important;
                }
            }

            #survey-app button, #survey-app .btn {
                border-radius: 100px;
                background-color: #0000;
                color: ${this.theme.button_text_color};
                border: 1px solid ${this.theme.button_color};
                outline: none;
                overflow: hidden;
            }
            #survey-app button:disabled, #survey-app .btn:disabled {
                background-color: #ccc;
                color: #000;
            }

            #survey-app .btn {
                display: inline-block;
                vertical-align: middle;
                -webkit-transform: perspective(1px) translateZ(0);
                transform: perspective(1px) translateZ(0);
                box-shadow: 0 0 1px rgba(0, 0, 0, 0);
                position: relative;
                -webkit-transition-property: color;
                transition-property: color;
                -webkit-transition-duration: 0.5s;
                transition-duration: 0.5s;
            }
            #survey-app .btn:before {
                content: "";
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: ${this.theme.button_color};
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transform-origin: 0 50%;
                transform-origin: 0 50%;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transition-duration: 0.5s;
                transition-duration: 0.5s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
            }
            #survey-app .btn:hover, #survey-app .btn:focus, #survey-app .btn:active {
                color: ${this.theme.button_text_color};
            }
            #survey-app .btn:hover:before, #survey-app .btn:focus:before, #survey-app .btn:active:before {
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
            }

            .survey h1, .survey h2, .survey h3, .survey h4, .survey h5, .survey h6, .survey .bottom-text {
                color: ${this.theme.text_header_color};
                word-wrap: break-word;
            }

            #survey-app .question, #survey-app .last-step , .question h1, .question p {
                color: ${this.theme.text_body_color} !important;
            }

            #survey-app .first-step {
                color: ${this.theme.text_header_color};
            }

            #survey-app .survey {
                color: ${this.theme.text_body_color};
                user-select: none;
                margin-bottom: 20vh;
            }

            #survey-app .chart > span {
                color: ${this.theme.text_header_color};
            }

            .survey td.matrix-header {
                color: ${this.theme.text_header_color};
            }

            .survey hr {
                border-top: 1px solid ${this.theme.text_header_color};
            }

            .survey-wrapper .next:disabled, .survey-wrapper .previous:disabled {
                display: none
            }

            .survey-wrapper .next, .survey-wrapper .previous {
                cursor: pointer;
                opacity: 0.8;
                width: 50px;
                height: 50px;
                padding: 0;
                margin: 0;
                border-radius: 50%;
                border: solid 2px #888589;
                background-color: #0000;
            }
            .survey-wrapper .next:hover, .survey-wrapper .previous:hover {
                opacity: 1;
            }
            @media (max-width: 500px) {
                .survey-wrapper .next, .survey-wrapper .previous {
                    color: #000;
                }
            }

            .survey .form-control {
                border-radius: 0;
                background-color: ${this.theme.element_color};
                color: ${this.theme.element_text_color};
                border: none;
                border-bottom: 3px solid ${this.theme.element_active_color};
            }

            .survey .form-control:focus {
                border-color: ${this.theme.element_active_color};
                background-color: ${this.theme.element_active_color};
                color: ${this.theme.element_text_active_color};
            }

            .survey input[type="checkbox"], .survey input[type="radio"] {
                display:none;
            }

            .survey input[type="checkbox"] + label, .survey input[type="radio"]:not(.range):not(.smileys) + label {
                cursor: pointer;
                padding: 0 7px;
                width: calc(100% - 43px);
                position: relative;
                margin-left: 43px;
                line-height: 35px;
                background-color: ${this.theme.element_color};
                color: ${this.theme.element_text_color};
            }
            .survey input[type="checkbox"] + label::before, .survey input[type="radio"]:not(.range):not(.smileys) + label::before {
                cursor: pointer;
                font-family: "Font Awesome 5 Free";
                width: 35px;
                height: 35px;
                background-color: ${this.theme.element_color};
                display: block;
                content: "\\f0c8";
                text-align: center;
                color: ${this.theme.element_text_color};
                position: absolute;
                left: -43px;
                top: 0;
            }
            .survey input[type="radio"]:not(.range):not(.smileys) + label::before {
                content: "\\f111";
            }

            .survey input[type="radio"].smileys + label {
                background-color: ${this.theme.element_color};
            }

            .survey input[type="checkbox"]:checked + label, .survey input[type="radio"]:checked + label {
                color: ${this.theme.element_text_active_color} !important;
                background-color: ${this.theme.element_active_color} !important;
            }
            .survey input[type="checkbox"]:checked + label::before, .survey input[type="radio"]:not(.range):not(.smileys):checked + label::before {
                content: "\\f14a";
                color: ${this.theme.element_text_active_color} !important;
                background-color: ${this.theme.element_active_color} !important;
            }
            .survey input[type="radio"]:not(.range):not(.smileys):checked + label::before {
                content: "\\f058";
            }

            .survey input[type="radio"].range + label, .survey .matrix-label {
                cursor: pointer;
                display: block;
                line-height: 35px;
                color: ${this.theme.element_text_color};
                background-color: ${this.theme.element_color};
            }
            .survey input[type="radio"].range:checked + label {
                cursor: pointer;
                color: ${this.theme.element_text_active_color} !important;
                background-color: ${this.theme.element_active_color} !important;
            }

            .survey input[type="radio"].matrix + label {
                cursor: pointer;
                font-family: "Font Awesome 5 Free";
                content: "\\f111";
            }
            .survey input[type="radio"].matrix:checked + label {
                content: "\\f058";
            }

            .survey td {
                border: none !important;
            }
            .survey input[type="radio"].matrix + label td .fa-circle {
                line-height: 35px;
                display: block;
            }
            .survey input[type="radio"].matrix:checked + label .fa-circle {
                display: none;
            }
            .survey input[type="radio"].matrix + label .fa-check-circle {
                line-height: 35px;
                display: none;
            }
            .survey input[type="radio"].matrix:checked + label .fa-check-circle {
                display: block;
            }
            `;

			// style.innerHTML = `
            // #survey-app .first-step, #survey-app .last-step {
            //     margin-top: 20vh;
            //     margin-bottom: 20vh;
            // }
			//
            // #survey-app .surveyCont {
            //     height: calc(100% - 40px) !important;
            // }
			//
            // @media (max-width: 500px) {
            //     .first-step, .last-step {
            //         margin-top: 0vh;
            //     }
            //     .survey-wrapper .footer {
            //         padding-top: 2px;
            //     }
            //     .surveyCont {
            //         height: calc(100% - 95px) !important;
            //     }
            // }
			//
            // #survey-app button, #survey-app .btn {
            //     border-radius: 100px;
            //     background-color: #0000;
            //     color: ${this.theme.buttonTextColor};
            //     border: 1px solid ${this.theme.buttonColor};
            //     outline: none;
            //     overflow: hidden;
            // }
            // #survey-app button:disabled, #survey-app .btn:disabled {
            //     background-color: #ccc;
            //     color: #000;
            // }
			//
            // #survey-app .btn {
            //     display: inline-block;
            //     vertical-align: middle;
            //     -webkit-transform: perspective(1px) translateZ(0);
            //     transform: perspective(1px) translateZ(0);
            //     box-shadow: 0 0 1px rgba(0, 0, 0, 0);
            //     position: relative;
            //     -webkit-transition-property: color;
            //     transition-property: color;
            //     -webkit-transition-duration: 0.5s;
            //     transition-duration: 0.5s;
            // }
            // #survey-app .btn:before {
            //     content: "";
            //     position: absolute;
            //     z-index: -1;
            //     top: 0;
            //     left: 0;
            //     right: 0;
            //     bottom: 0;
            //     background: ${this.theme.buttonColor};
            //     -webkit-transform: scaleX(0);
            //     transform: scaleX(0);
            //     -webkit-transform-origin: 0 50%;
            //     transform-origin: 0 50%;
            //     -webkit-transition-property: transform;
            //     transition-property: transform;
            //     -webkit-transition-duration: 0.5s;
            //     transition-duration: 0.5s;
            //     -webkit-transition-timing-function: ease-out;
            //     transition-timing-function: ease-out;
            // }
            // #survey-app .btn:hover, #survey-app .btn:focus, #survey-app .btn:active {
            //     color: ${this.theme.buttonTextColor};
            // }
            // #survey-app .btn:hover:before, #survey-app .btn:focus:before, #survey-app .btn:active:before {
            //     -webkit-transform: scaleX(1);
            //     transform: scaleX(1);
            // }
			//
            // .survey h1, .survey h2, .survey h3, .survey h4, .survey h5, .survey h6, .survey .bottom-text {
            //     color: ${this.theme.textHeaderColor};
            //     word-wrap: break-word;
            // }
			//
            // #survey-app .question, #survey-app .last-step , .question h1, .question p {
            //     color: ${this.theme.textBodyColor} !important;
            // }
			//
            // #survey-app .first-step {
            //     color: ${this.theme.textHeaderColor};
            // }
			//
            // #survey-app .survey {
            //     color: ${this.theme.textBodyColor};
            //     user-select: none;
            //     margin-bottom: 20vh;
            // }
			//
            // #survey-app .chart > span {
            //     color: ${this.theme.textHeaderColor};
            // }
			//
            // .survey td.matrix-header {
            //     color: ${this.theme.textHeaderColor};
            // }
			//
            // .survey hr {
            //     border-top: 1px solid ${this.theme.textHeaderColor};
            // }
			//
            // .survey-wrapper .next:disabled, .survey-wrapper .previous:disabled {
            //     display: none
            // }
			//
            // .survey-wrapper .next, .survey-wrapper .previous {
            //     cursor: pointer;
            //     opacity: 0.8;
            //     width: 50px;
            //     height: 50px;
            //     padding: 0;
            //     margin: 0;
            //     border-radius: 50%;
            //     border: solid 2px #888589;
            //     background-color: #0000;
            // }
            // .survey-wrapper .next:hover, .survey-wrapper .previous:hover {
            //     opacity: 1;
            // }
            // @media (max-width: 500px) {
            //     .survey-wrapper .next, .survey-wrapper .previous {
            //         color: #000;
            //     }
            // }
			//
            // .survey .form-control {
            //     border-radius: 0;
            //     background-color: ${this.theme.elementColor};
            //     color: ${this.theme.elementTextColor};
            //     border: none;
            //     border-bottom: 3px solid ${this.theme.elementActiveColor};
            // }
			//
            // .survey .form-control:focus {
            //     border-color: ${this.theme.elementActiveColor};
            //     background-color: ${this.theme.elementActiveColor};
            //     color: ${this.theme.elementTextActiveColor};
            // }
			//
            // .survey input[type="checkbox"], .survey input[type="radio"] {
            //     display:none;
            // }
			//
            // .survey input[type="checkbox"] + label, .survey input[type="radio"]:not(.range):not(.smileys) + label {
            //     cursor: pointer;
            //     padding: 0 7px;
            //     width: calc(100% - 43px);
            //     position: relative;
            //     margin-left: 43px;
            //     line-height: 35px;
            //     background-color: ${this.theme.elementColor};
            //     color: ${this.theme.elementTextColor};
            // }
            // .survey input[type="checkbox"] + label::before, .survey input[type="radio"]:not(.range):not(.smileys) + label::before {
            //     cursor: pointer;
            //     font-family: "Font Awesome 5 Free";
            //     width: 35px;
            //     height: 35px;
            //     background-color: ${this.theme.elementColor};
            //     display: block;
            //     content: "\\f0c8";
            //     text-align: center;
            //     color: ${this.theme.elementTextColor};
            //     position: absolute;
            //     left: -43px;
            //     top: 0;
            // }
            // .survey input[type="radio"]:not(.range):not(.smileys) + label::before {
            //     content: "\\f111";
            // }
			//
            // .survey input[type="radio"].smileys + label {
            //     background-color: ${this.theme.elementColor};
            // }
			//
            // .survey input[type="checkbox"]:checked + label, .survey input[type="radio"]:checked + label {
            //     color: ${this.theme.elementTextActiveColor} !important;
            //     background-color: ${this.theme.elementActiveColor} !important;
            // }
            // .survey input[type="checkbox"]:checked + label::before, .survey input[type="radio"]:not(.range):not(.smileys):checked + label::before {
            //     content: "\\f14a";
            //     color: ${this.theme.elementTextActiveColor} !important;
            //     background-color: ${this.theme.elementActiveColor} !important;
            // }
            // .survey input[type="radio"]:not(.range):not(.smileys):checked + label::before {
            //     content: "\\f058";
            // }
			//
            // .survey input[type="radio"].range + label, .survey .matrix-label {
            //     cursor: pointer;
            //     display: block;
            //     line-height: 35px;
            //     color: ${this.theme.elementTextColor};
            //     background-color: ${this.theme.elementColor};
            // }
            // .survey input[type="radio"].range:checked + label {
            //     cursor: pointer;
            //     color: ${this.theme.elementTextActiveColor} !important;
            //     background-color: ${this.theme.elementActiveColor} !important;
            // }
			//
            // .survey input[type="radio"].matrix + label {
            //     cursor: pointer;
            //     font-family: "Font Awesome 5 Free";
            //     content: "\\f111";
            // }
            // .survey input[type="radio"].matrix:checked + label {
            //     content: "\\f058";
            // }
			//
            // .survey td {
            //     border: none !important;
            // }
            // .survey input[type="radio"].matrix + label td .fa-circle {
            //     line-height: 35px;
            //     display: block;
            // }
            // .survey input[type="radio"].matrix:checked + label .fa-circle {
            //     display: none;
            // }
            // .survey input[type="radio"].matrix + label .fa-check-circle {
            //     line-height: 35px;
            //     display: none;
            // }
            // .survey input[type="radio"].matrix:checked + label .fa-check-circle {
            //     display: block;
            // }
            // `;

			document.getElementsByTagName('body')[0].appendChild(style);
		},
	},
	watch: {
		theme: {
			deep: true,
			handler: function(newTheme) {
				this.theme = newTheme;
				this.createCssClasses();
			},
		},
		backgroundPicActive: function() {
			this.createCssClasses();
		},
	},
};
</script>

<style scoped>
#survey-app {
	height: 75vh;
	background-color: #ffffff;
	border: 2px solid black;
}

.background {
	height: calc(100% - 3px) !important;
	width: calc(100% - 1.9rem - 3px);
}

.background:before {
	content: ' ';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: var(--opacity);

	background-image: var(--image);

	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}
</style>
