<template>
	<div>
		<ContentLoading size="1.5" v-if="pageProcesses.isLoading"></ContentLoading>
		<template v-if="!pageProcesses.isLoading">
			<div class="row">
				<div class="col-12">
					<div class="card card-custom card-stretch gutter-b">
						<div class="card-header border-0 cursor-pointer" v-on:click.prevent="toggleEdit">
							<h3 class="card-title align-items-start flex-column">
								<span class="card-label font-weight-bolder text-dark pt-5">{{ $t('VIEWS.Survey.Theme.title') }}</span>
							</h3>
							<div class="card-toolbar">
								<i class="fas fa-angle-up" v-if="isEditable"></i>
								<i class="fas fa-angle-down" v-if="!isEditable"></i>
							</div>
						</div>
						<div class="card-body py-3" v-if="isEditable">
							<div class="row">
								<div class="col-12 col-md-3">
									<div class="form-group">
										<label>Bezeichnung:</label>
										<input type="text" class="form-control" v-model="theme.name" />
										<span class="form-text text-muted"></span>
									</div>
								</div>
								<div class="col-12 col-md-3">
									<div class="form-group">
										<label>Startknopf Text:</label>
										<input type="text" class="form-control" v-model="theme.button_text" />
										<span class="form-text text-muted"></span>
									</div>
								</div>
								<div class="d-none d-md-inline-block col-3">
									<div class="form-group"></div>
								</div>
								<div class="d-none d-md-inline-block col-3">
									<div class="form-group"></div>
								</div>
								<div class="col-12 col-md-3">
									<div class="form-group">
										<label>Abschlusstext Überschrift:</label>
										<input type="text" class="form-control" v-model="theme.success_text_header" />
										<span class="form-text text-muted"></span>
									</div>
								</div>
								<div class="col-12 col-md-3">
									<div class="form-group">
										<label>Abschlusstext:</label>
										<input type="text" class="form-control" v-model="theme.success_text_body" />
										<span class="form-text text-muted"></span>
									</div>
								</div>
								<div class="d-none d-md-inline-block col-3">
									<div class="form-group"></div>
								</div>
								<div class="d-none d-md-inline-block col-3">
									<div class="form-group"></div>
								</div>
								<div class="col-12 col-md-3">
									<ColorInput title="Header Textfarbe" :color="theme.text_header_color" prop-name="text_header_color" @set-color="setThemeColor"></ColorInput>
								</div>
								<div class="col-12 col-md-3">
									<ColorInput title="Body Textfarbe" :color="theme.text_body_color" prop-name="text_body_color" @set-color="setThemeColor"></ColorInput>
								</div>
								<div class="d-none d-md-inline-block col-3">
									<div class="form-group"></div>
								</div>
								<div class="d-none d-md-inline-block col-3">
									<div class="form-group"></div>
								</div>
								<div class="col-12 col-md-3">
									<ColorInput title="Element Hintergrundfarbe Normal" :color="theme.element_color" prop-name="element_color" @set-color="setThemeColor"></ColorInput>
								</div>
								<div class="col-12 col-md-3">
									<ColorInput title="Element Hintergrundfarbe Ausgewählt" :color="theme.element_active_color" prop-name="element_active_color" @set-color="setThemeColor"></ColorInput>
								</div>
								<div class="col-12 col-md-3">
									<ColorInput title="Element Textfarbe Normal" :color="theme.element_text_color" prop-name="element_text_color" @set-color="setThemeColor"></ColorInput>
								</div>
								<div class="col-12 col-md-3">
									<ColorInput title="Element Textfarbe Ausgewählt" :color="theme.element_text_active_color" prop-name="element_text_active_color" @set-color="setThemeColor"></ColorInput>
								</div>
								<div class="col-12 col-md-3">
									<ColorInput title="Weiter Knopf Hintergrundfarbe" :color="theme.button_color" prop-name="button_color" @set-color="setThemeColor"></ColorInput>
								</div>
								<div class="col-12 col-md-3">
									<ColorInput title="Weiter Knopf Textfarbe" :color="theme.button_text_color" prop-name="button_text_color" @set-color="setThemeColor"></ColorInput>
								</div>
								<div class="d-none d-md-inline-block col-3">
									<div class="form-group"></div>
								</div>
								<div class="d-none d-md-inline-block col-3">
									<div class="form-group"></div>
								</div>
								<div class="col-12 col-md-3">
									<ColorInput title="Allgemeine Hintergrundfarbe" :color="theme.background_color" prop-name="background_color" @set-color="setThemeColor"></ColorInput>
								</div>

								<div class="col-12 col-md-3">
									<div class="form-group">
										<label>Hintergrundbild verwenden</label>
										<div class="custom-control custom-switch custom-switch-md">
											<input type="checkbox" class="custom-control-input" id="backgroundActive" :value="true" v-model="backgroundPicActive" />
											<label class="custom-control-label" for="backgroundActive">Verwenden</label>
										</div>
									</div>
								</div>
								<div class="col-12 col-md-3">
									<transition name="slide">
										<div class="form-group mt-2 mb-3" v-if="backgroundPicActive.indexOf(true) > -1">
											<label>Hintergrundbild</label>
											<input type="file" name="backgroundPic" placeholder="Hintergrundbild" class="form-control-file form-control-solid my-auto" accept="image/jpeg,image/png" v-on:change="pictureChange" />
											<span class="form-text text-muted">Bitte Hintergrundbild auswählen.</span>
										</div>
									</transition>
								</div>
								<div class="col-12 col-md-3">
									<transition name="slide">
										<div class="form-group mt-2 mb-3" v-if="backgroundPicActive.indexOf(true) > -1">
											<label>Hintergrundbild Transparenz</label>
											<input type="range" name="picOpacity" v-model="theme.background_picture_opacity" placeholder="Hintergrundbild" min="0" max="1" step="0.05" class="form-control-range form-control-solid my-auto" />
											<span class="form-text text-muted" v-text="opacityPercent"></span>
										</div>
									</transition>
								</div>

								<div class="col-12 col-md-3">
									<div class="form-group">
										<label>Logo verwenden</label>
										<div class="custom-control custom-switch custom-switch-md">
											<input type="checkbox" class="custom-control-input" id="logoActive" :value="true" v-model="logoActive" />
											<label class="custom-control-label" for="logoActive">Verwenden</label>
										</div>
									</div>
								</div>
								<div class="col-12 col-md-3">
									<transition name="slide">
										<div class="form-group mt-2 mb-3" v-if="logoActive.indexOf(true) > -1">
											<label>Logo</label>
											<input type="file" placeholder="Logo" class="form-control-file form-control-solid my-auto" accept="image/jpeg,image/png" v-on:change="logoChange" />
											<span class="form-text text-muted">Bitte Logo auswählen.</span>
										</div>
									</transition>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12">
					<DesignerPreview :theme="theme" :logoActive="logoActive" :backgroundPicActive="backgroundPicActive"></DesignerPreview>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import ContentLoading from '@/view/component/misc/ContentLoading';
import ApiService from '@/core/services/api.service';
import { PRINOR_TOASTS } from '@/core/services/toast.service';
import ColorInput from '@/view/component/designer/ColorInput';
import DesignerPreview from '@/view/component/designer/DesignerPreview';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import {PRINOR_ACTIONS, PRINOR_PROCESSES} from '@/core/services/actions.service';
import { mapGetters } from 'vuex';
import {SET_ACTION} from "@/core/services/store/actions.module";
import {PRINOR_PERMISSION} from "@/core/services/permissions.service";
import Swal from "sweetalert2";

export default {
	name: 'SurveyDesign',
	components: { DesignerPreview, ColorInput, ContentLoading },
	data: function() {
		return {
			theme: {
				id: '',
				name: '',
				default: false,
				header_color: '',
				body_color: '',
				button_text: '',
				success_text_header: '',
				success_text_body: '',
				text_header_color: '',
				text_body_color: '',
				element_color: '',
				element_active_color: '',
				element_text_color: '',
				element_text_active_color: '',
				button_color: '',
				button_text_color: '',
				logo: '',
				background_picture: '',
				background_color: '',
				background_picture_opacity: 0,
				company_id: '',
				organization_id: '',
			},
			backgroundPicActive: [false],
			logoActive: [],
			isEditable: false,
			logoFile: '',
			bgFile: '',
			saveButtonId: '',
		};
	},
	computed: {
		...mapGetters(['pageProcesses']),
		storageUrl: function() {
			return process.env.VUE_APP_URL_STORAGE;
		},
		opacityPercent: function() {
			return Math.round(this.theme.background_picture_opacity * 100) + '%';
		},
	},
	mounted() {
		this.initTheme(null);
	},
	methods: {
		initTheme: function(toId) {
			this.$store.dispatch(SET_ACTION, []);
			this.$store.dispatch(SET_BREADCRUMB, [
				{
					title: this.$t('MENU.Survey.surveys'),
					route: '/survey',
				},
				{
					title: this.$t('MENU.Survey.designs'),
					route: '/survey/designs',
				},
				{ title: this.$t('VIEWS.Survey.Theme.title') },
			]);
			this.saveButtonId = PRINOR_ACTIONS.create(this, this.$t('GENERAL.Forms.save'), this.onSave, 'success', '', false, !this.hasPermission('action.theme.edit'));
			PRINOR_PROCESSES.isLoading();

			if ('id' in this.$route.params || toId != null) {
				if (toId != null) {
					this.$route.params.id = toId;
				}
			}

			this.onGet();
		},
		hasPermission(name) {
			return PRINOR_PERMISSION.action(name);
		},
		setThemeColor: function(prop, val) {
			this.theme[prop] = val;
		},
		logoChange: function(event) {
			const file = event.target.files[0];
			this.theme.logo = URL.createObjectURL(file);
			this.logoFile = file;
		},
		pictureChange: function(event) {
			const file = event.target.files[0];
			this.theme.background_picture = URL.createObjectURL(file);
			this.bgFile = file;
		},
		toggleEdit: function() {
			this.isEditable = !this.isEditable;
		},
		setUrl: function() {
			if (this.theme.logo != null && this.theme.logo !== '') {
				this.theme.logo = this.storageUrl + '/surveyAppearance/' + this.theme.logo;
			} else {
				this.logoActive = [];
			}

			if (this.theme.background_picture != null && this.theme.background_picture !== '') {
				this.theme.background_picture = this.storageUrl + '/surveyAppearance/' + this.theme.background_picture;
			} else {
				this.backgroundPicActive = [];
			}
		},
		onGet: function() {
			PRINOR_PROCESSES.isLoading();
			this.get().then(data => {
				this.theme = data.data;
				this.setUrl();
				this.bgFile = '';
				this.logoFile = '';

				if (this.theme.background_icture != '') {
					this.backgroundPicActive = [true];
				} else {
					this.backgroundPicActive = [false];
				}
				if (this.theme.logo != '') {
					this.logoActive = [true];
				} else {
					this.logoActive = [false];
				}

				if (this.$route.params.id == 'create') {
					this.theme.id = 'create';
				}
			});
		},
		get: function() {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.get('theme/' + this.$route.params.id).then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === false) {
							PRINOR_TOASTS.general.error(this, data.data.error);
						}

						resolve(data.data);
					}
				});
			});
		},
		onSave: function() {
			PRINOR_PROCESSES.isUpdating();
			let bgActive = false;
			let logoActive = false;

			if (this.backgroundPicActive.indexOf(true) !== -1) {
				bgActive = true;
			}
			if (this.logoActive.indexOf(true) !== -1) {
				logoActive = true;
			}

			let formData = new FormData();

			Object.keys(this.theme).forEach(key => {
				formData.append(key, this.theme[key]);
			});

			formData.append('logoFile', this.logoFile);
			formData.append('bgFile', this.bgFile);
			formData.append('backgroundPicActive', bgActive);
			formData.append('logoActive', logoActive);

			this.save(formData).then(data => {
				if (this.$route.params.id === "create") {
					this.$router.push({ name: 'survey-design', params: { id: data.data.id } });
				}
			});
		},
		save: function(formData) {
			return new Promise(resolve => {
				ApiService.setHeader();
				ApiService.post('theme/' + this.$route.params.id, formData).then(data => {
					PRINOR_PROCESSES.reset();
					if (ApiService.checkSuccess(data)) {
						if (data.data.success === true) {
							PRINOR_TOASTS.save.confirmation(this);
						} else {
							PRINOR_TOASTS.save.error(this, data.data.error);
						}

						resolve(data.data);
					}
				});
			});
		},
	},
	watch: {
		pageProcesses: {
			deep: true,
			handler: function(newVal) {
				PRINOR_ACTIONS.setProcessing(this, this.saveButtonId, newVal.isUpdating);
			},
		},
		$route: {
			deep: true,
			handler: function(newVal) {
				if ('id' in newVal.params) {
					this.initTheme(newVal.params.id);
				}
			}
		},
	},
	beforeRouteLeave (to, from, next) {
		Swal.fire({
			title: this.$t('GENERAL.General.leave.title'),
			text: this.$t('GENERAL.General.leave.text'),
			icon: 'warning',
			confirmButtonText: this.$t('GENERAL.General.yes'),
			showCancelButton: true,
			cancelButtonText: this.$t('GENERAL.General.no'),
			customClass: {
				confirmButton: 'btn btn-danger',
				cancelButton: 'btn btn-secondary',
			},
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
		}).then(result => {
			if (result.isConfirmed) {
				return next();
			}
			else if (result.isDismissed) {
				return next(false);
			}
		});
	}
};
</script>

<style scoped>
/* for sm */

.custom-switch.custom-switch-sm .custom-control-label {
	padding-left: 1rem;
	padding-bottom: 1rem;
}

.custom-switch.custom-switch-sm .custom-control-label::before {
	height: 1rem;
	width: calc(1rem + 0.75rem);
	border-radius: 2rem;
}

.custom-switch.custom-switch-sm .custom-control-label::after {
	width: calc(1rem - 4px);
	height: calc(1rem - 4px);
	border-radius: calc(1rem - (1rem / 2));
}

.custom-switch.custom-switch-sm .custom-control-input:checked ~ .custom-control-label::after {
	transform: translateX(calc(1rem - 0.25rem));
}

/* for md */

.custom-switch.custom-switch-md .custom-control-label {
	padding-left: 2rem;
	padding-bottom: 1.5rem;
}

.custom-switch.custom-switch-md .custom-control-label::before {
	height: 1.5rem;
	width: calc(2rem + 0.75rem);
	border-radius: 3rem;
}

.custom-switch.custom-switch-md .custom-control-label::after {
	width: calc(1.5rem - 4px);
	height: calc(1.5rem - 4px);
	border-radius: calc(2rem - (1.5rem / 2));
}

.custom-switch.custom-switch-md .custom-control-input:checked ~ .custom-control-label::after {
	transform: translateX(calc(1.5rem - 0.25rem));
}

/* for lg */

.custom-switch.custom-switch-lg .custom-control-label {
	padding-left: 3rem;
	padding-bottom: 2rem;
}

.custom-switch.custom-switch-lg .custom-control-label::before {
	height: 2rem;
	width: calc(3rem + 0.75rem);
	border-radius: 4rem;
}

.custom-switch.custom-switch-lg .custom-control-label::after {
	width: calc(2rem - 4px);
	height: calc(2rem - 4px);
	border-radius: calc(3rem - (2rem / 2));
}

.custom-switch.custom-switch-lg .custom-control-input:checked ~ .custom-control-label::after {
	transform: translateX(calc(2rem - 0.25rem));
}

/* for xl */

.custom-switch.custom-switch-xl .custom-control-label {
	padding-left: 4rem;
	padding-bottom: 2.5rem;
}

.custom-switch.custom-switch-xl .custom-control-label::before {
	height: 2.5rem;
	width: calc(4rem + 0.75rem);
	border-radius: 5rem;
}

.custom-switch.custom-switch-xl .custom-control-label::after {
	width: calc(2.5rem - 4px);
	height: calc(2.5rem - 4px);
	border-radius: calc(4rem - (2.5rem / 2));
}

.custom-switch.custom-switch-xl .custom-control-input:checked ~ .custom-control-label::after {
	transform: translateX(calc(2.5rem - 0.25rem));
}
</style>
